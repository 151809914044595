import React from 'react';
import { connect } from 'react-redux';
import Link from 'next/link';
import { withRouter } from 'next/router';
/* eslint-disable-next-line no-restricted-imports */
import styled from 'styled-components';

import getFontDetailsByLabel from '@headout/aer/src/tokens/typography';

import Conditional from 'Components/common/conditional';
import Image from 'Components/common/image';
import LSpan from 'Components/common/localizedTags/localizedSpan';

import { trackEvent } from 'Utils/analytics';
import { getCollectionUrlAsPath } from 'Utils/collectionsUtils';
import { getLocalisedPrice } from 'Utils/currencyUtils';
import {
	getCollectionCardById,
	getCurrenciesMap,
	getCurrentCityCode,
} from 'Utils/stateUtils';
import { getHumanReadableStringFromSanitizedIdentifier } from 'Utils/stringUtils';

import { ANALYTICS_EVENTS, ANALYTICS_PROPERTIES } from 'Constants/analytics';
import { GLOBAL_CITY_CODE } from 'Constants/constants';
import { strings } from 'Constants/strings';

import colors from 'Static/typography/colors';
import TYPE_LABELS from 'Static/typography/labels';

/* Styled Components */
const CollectionContainerWrapper = styled.div`
	display: flex;
	margin: 0.75rem;
	cursor: pointer;
	position: relative;
	top: 0;

	&.collection-card {
		font-size: 0.625rem;
		line-height: 0.625rem;
	}

	&:hover {
		top: -0.1875rem;
		transition: top 0.2s ease-in-out 0s;
	}
`;

const CollectionCardContainer = styled.div`
	display: flex;
	flex-direction: column;
`;

const ImageContainer = styled.div`
	width: 11.25rem;
	height: 15rem;
	border-radius: 0.25rem;

	img {
		width: 100%;
		height: 100%;
		object-fit: contain;
		border-radius: 0.25rem;
	}
`;

const CollectionIndex = styled.div`
	position: absolute;
	top: 0.5rem;
	left: 0.5rem;
	width: 1.5rem;
	height: 1.5rem;
	background: ${colors.GREY_DS.G8};
	border: 1px solid ${colors.GREY_DS.G5};
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	color: ${colors.GREY_DS.G2};
	${getFontDetailsByLabel(TYPE_LABELS.SUBHEADING_REGULAR)};
`;

const CollectionName = styled.div`
	margin-top: 0.5rem;
	width: 11.25rem;

	.collection-name {
		color: ${colors.GREY['44']};
		${getFontDetailsByLabel(TYPE_LABELS.HEADING_PRODUCT_CARD)};
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
		overflow: hidden;
	}
`;

const CollectionPrice = styled.div`
	margin-top: 0.25rem;

	.collection-price {
		color: ${colors.GREY['66']};
		${getFontDetailsByLabel(TYPE_LABELS.UI_LABEL_REGULAR)};
	}
`;

const CollectionCard = ({
	collectionCardInfo,
	lang,
	id,
	currentCityCode,
	currenciesMap,
	collectionNamePrefix,
	showPrice = true,
	isSearch,
	onCardClick,
	appendCityCode = true,
	index,
	parentIdentifier,
}: any) => {
	const {
		displayName,
		heroImageUrl,
		heroMedia,
		cardMedia,
		cardImageUrl,
		startingPrice,
		urlSlugs,
		cityCode: collectionPrimaryCityCode,
		cityDisplayName,
		name,
	} = collectionCardInfo;

	if (!urlSlugs) return null;

	const isGlobalCity =
		currentCityCode === null || currentCityCode === GLOBAL_CITY_CODE;
	const cityCodeParam =
		appendCityCode &&
		!isGlobalCity &&
		currentCityCode !== collectionPrimaryCityCode
			? currentCityCode
			: '';

	const collectionUrlAsPath = getCollectionUrlAsPath({
		urlSlugs,
		lang,
		city: cityCodeParam,
	});

	let collectionStartingPrice;

	if (startingPrice) {
		const { listingPrice, currency } = startingPrice;
		const currencyObj = currenciesMap?.[currency];
		collectionStartingPrice = getLocalisedPrice(
			listingPrice,
			currencyObj,
			lang,
		);
	}

	let subHeading =
		currentCityCode &&
		currentCityCode !== GLOBAL_CITY_CODE &&
		collectionStartingPrice
			? `${strings.FROM} ${collectionStartingPrice}`
			: cityDisplayName;

	if (isSearch)
		subHeading = collectionStartingPrice
			? `${strings.FROM} ${collectionStartingPrice}`
			: cityDisplayName;

	const onClick = () => {
		trackEvent({
			eventName: ANALYTICS_EVENTS.COLLECTION_CARD_CLICKED,
			[ANALYTICS_PROPERTIES.COLLECTION_ID]: id,
			[ANALYTICS_PROPERTIES.COLLECTION_NAME]: name,
			[ANALYTICS_PROPERTIES.CARD_TYPE]: 'Standard Card',
			...(index !== undefined && {
				[ANALYTICS_PROPERTIES.RANKING]: index + 1,
			}),
			...(parentIdentifier
				? {
						[ANALYTICS_PROPERTIES.SECTION]:
							getHumanReadableStringFromSanitizedIdentifier(
								parentIdentifier,
							),
				  }
				: {}),
		});
		onCardClick?.();
	};

	return (
		<Link href={collectionUrlAsPath} key={id} passHref legacyBehavior>
			<CollectionContainerWrapper
				onClick={onClick}
				className={'collection-card'}
				as={'a'}
				target={'_blank'}
			>
				<CollectionCardContainer>
					<ImageContainer>
						<Image
							src={
								cardImageUrl ||
								cardMedia?.url ||
								heroImageUrl ||
								heroMedia?.url
							}
							width={180}
							height={240}
							alt={displayName}
							priority={false}
						/>
					</ImageContainer>

					<Conditional if={collectionNamePrefix}>
						<CollectionIndex>
							<span>{collectionNamePrefix}</span>
						</CollectionIndex>
					</Conditional>
					<CollectionName>
						<LSpan className='collection-name'>{displayName}</LSpan>
					</CollectionName>

					<Conditional if={showPrice}>
						<CollectionPrice>
							<LSpan className='collection-price'>
								{subHeading}
							</LSpan>
						</CollectionPrice>
					</Conditional>
				</CollectionCardContainer>
			</CollectionContainerWrapper>
		</Link>
	);
};

const mapStateToProps = (state: any, ownProps: any) => {
	const {
		id,
		router: {
			query: { lang = 'en' },
		},
	} = ownProps;
	return {
		collectionCardInfo: getCollectionCardById(state, id),
		currentCityCode: getCurrentCityCode(state),
		currenciesMap: getCurrenciesMap(state),
		lang,
	};
};

const mapSearchStateToProps = (state: any, ownProps: any) => {
	const {
		router: {
			query: { lang = 'en' },
		},
	} = ownProps;

	return {
		currentCityCode: getCurrentCityCode(state),
		currenciesMap: getCurrenciesMap(state),
		lang,
	};
};

export default withRouter(connect(mapStateToProps)(CollectionCard));

const SearchCollectionCard = withRouter(
	connect(mapSearchStateToProps)(CollectionCard),
);
export { SearchCollectionCard };
